var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.statisInfo, function (statis, index) {
        return _c("span", { key: index, staticClass: "statis" }, [
          _vm._v(_vm._s(statis.key + ":" + statis.value)),
        ])
      }),
      _c(
        "vxe-table",
        { attrs: { data: _vm.records } },
        [
          _c("vxe-table-column", {
            attrs: { type: "seq", title: "序号", width: "60" },
          }),
          _vm._l(_vm.columns, function (column, index) {
            return _c("vxe-table-column", {
              key: index,
              attrs: {
                field: column.field,
                title: column.label,
                formatter: column.formatter,
              },
            })
          }),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }