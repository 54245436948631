<!--
 * @Author: 冯杰
 * @Date: 2021-08-09 16:38:37
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-08-23 15:20:28
 * @FilePath: \crm2.0\found\views\cps\views\execute\package\table\components\city.vue
-->
<template>
  <div>
    <el-row>
      <el-col :span="8">
        <el-select v-model="sheng" placeholder="请选择省" @change="clickSheng">
          <el-option
            v-for="item in options1"
            :key="item.regionCode"
            :label="item.regionName"
            :value="item.regionCode"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-select v-model="shi" placeholder="请选择市" @change="clickShi">
          <el-option
            v-for="item in options2"
            :key="item.regionCode"
            :label="item.regionName"
            :value="item.regionCode"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="8">
        <el-select v-model="qu" placeholder="请选择区" @change="clickQu">
          <el-option
            v-for="item in options3"
            :key="item.regionCode"
            :label="item.regionName"
            :value="item.regionCode"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  data() {
    return {
      options1: [],
      options2: [],
      options3: [],
      sheng: '',
      shi: '',
      qu: '',
      clear: false,
    };
  },
  mounted() {
    this.getSheng();
    // console.log(this.sheng, this.shi, this.qu);
  },
  methods: {
    getSheng() {
      request
        .post('/mdm/mdmRegionController/selectStartWithProvince', { regionLevel: '1' })
        .then((res) => {
          if (!res.success) return;
          this.options1 = { ...res.result };
        });
    },

    clickSheng() {
      this.getShi();
    },

    getShi() {
      if (this.sheng) {
        request
          .post('/mdm/mdmRegionController/selectStartWithProvince', { parentCode: this.sheng })
          .then((res) => {
            if (!res.success) return;
            this.options2 = { ...res.result };
          });
      }
    },

    clickShi() {
      this.getQu();
    },

    getQu() {
      if (this.shi) {
        request
          .post('/mdm/mdmRegionController/selectStartWithProvince', { parentCode: this.shi })
          .then((res) => {
            if (!res.success) return;
            this.options3 = { ...res.result };
          });
      }
    },

    clickQu() {
      this.$emit('city', this.sheng + this.shi + this.qu);
    },
  },
};
</script>
