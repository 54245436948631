import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'dealer_profit_detail_form',
      formParentCode: 'CRM20211030000002753',
    };
  },

  methods: {

    // 完成渲染
    formComplete() {
      if (this.formConfig.code === 'view') {
        request.post('/mdm/productchange/getDetail', { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            this.setValue({
              ...res.result,
            });
          }
        });
      }
    },

    // 提交
    submit() {
      this.$emit('onClose');
    },
  },
};
