/*
 * @Autor: yzr
 * @Date: 2021-08-10 10:09:40
 * @LastEditors: yzr
 * @LastEditTime: 2021-08-25 14:22:35
 * @Description: 终端余额
 */
import TerminalBalance from './TerminalBalance.vue';

export default TerminalBalance;
