<!--
 * @Autor: yzr
 * @Date: 2021-08-23 10:09:39
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-14 14:11:27
 * @Description: 明细信息展示表格
-->

<template>
  <div>
    <span class="statis" v-for="(statis, index) in statisInfo" :key="index">{{statis.key + ":" + statis.value}}</span>
    <vxe-table
      :data="records"
    >
      <vxe-table-column type="seq" title="序号" width="60"></vxe-table-column>
      <vxe-table-column v-for="(column, index) in columns" :key="index" :field="column.field" :title="column.label" :formatter="column.formatter"></vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
export default {
  props: {
    columns: Array,
    statisInfo: Array,
    records: Array,
  },
  mounted() {
    console.log('props', this.columns);
  },
};
</script>

<style lang="less">
.statis {
  display: inline-block;
  margin: 0 20px 20px 0;
}
</style>
