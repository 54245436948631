/*
 * @Autor: yzr
 * @Date: 2021-08-10 10:09:26
 * @LastEditors: yzr
 * @LastEditTime: 2021-08-10 10:57:05
 * @Description: 经销商余额
 */
import DealerBalance from './DealerBalance.vue';

export default DealerBalance;
