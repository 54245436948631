/*
 * @Autor: yzr
 * @Date: 2021-08-23 09:49:42
 * @LastEditors: yzr
 * @LastEditTime: 2021-08-23 09:50:31
 * @Description: 明细表单
 */
import Form from './form.vue';

export default Form;
