<!--
 * @Autor: yzr
 * @Date: 2021-08-23 09:49:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-14 14:48:28
 * @Description: 明细表单
-->
<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import DetailTable from '../../../../components/detailTable.vue';

formCreate.component('detailTable', DetailTable);

export default {
  name: 'Form',
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'cps-rights-terminalBalance-form',
      fenliTotal: 0, // 分利总费用
      writtenOff: 0, // 已核销总费用
      notWrittenOff: 0, // 未核销总费用
    };
  },
  methods: {
    // 表单渲染完成后调用
    formComplete() {
      this.getDetail();
    },
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'detailTable') {
        item.props = {
          ...item.props,
          columns: [
            {
              field: 'sourceType',
              label: '业务类型',
            },
            {
              field: 'sourceCode',
              label: '业务来源单号',
            },
            {
              field: 'amount',
              label: '分利费用',
            },
            {
              field: 'auditedFee',
              label: '已核销费用',
            },
            {
              field: 'unAuditFee',
              label: '未核销费用',
            },
            {
              field: 'auditState',
              label: '核销状态',
              formatter: ({ cellValue }) => {
                let billState = '';
                if (cellValue === 1) billState = '未核销';
                else if (cellValue === 2) billState = '部分核销';
                else billState = '已核销';
                return billState;
              },
            },
            {
              field: 'modifyTime',
              label: '更新时间',
            },
            {
              field: 'modifyAccount',
              label: '更新人',
            },
            {
              field: 'remark',
              label: '备注',
            },
          ],
          statisInfo: [
            {
              key: '分利总费用',
              value: 0,
            },
            {
              key: '已核销总费用',
              value: 0,
            },
            {
              key: '未核销总费用',
              value: 0,
            },
          ],
        };
      }
      return item;
    },
    // 表单提交
    submit() {},
    // 获取表格数据
    getDetail() {
      request.get('/cps/v1/cost/terminalDetail/findByCostTerminalId', { costTerminalId: this.formConfig.row.id }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.setValue({
          ...this.formConfig.row,
          type: '终端',
        });

        res.result.records.forEach((val) => {
          this.fenliTotal += val.amount;
          this.writtenOff += val.auditedFee;
          this.notWrittenOff += val.unAuditFee;
        });

        this.getRule('detailTable').props.records = res.result.records;
        this.getRule('detailTable').props.statisInfo = [
          {
            key: '分利总费用',
            value: Number(this.fenliTotal).toFixed(2),
          },
          {
            key: '已核销总费用',
            value: Number(this.writtenOff).toFixed(2),
          },
          {
            key: '未核销总费用',
            value: Number(this.notWrittenOff).toFixed(2),
          },
        ];
      });
    },
  },
};
</script>
