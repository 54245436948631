<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from './form';

export default {
  name: 'modules',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestType: 'GET',
      requestUrl: '/cps-mobile/h5/cost/dealer/findByConditions',
      params: {
      },
      formConfig: {
        code: 'view',
      },
      modalConfig: {},
    };
  },
  async created() {
    await this.getConfigList('cash_unfreezing_list');
  },
  methods: {
    modalClick({ val, row }) {
      this.modalConfig = {
        title: '经销商现金解冻明细',
      };
      switch (val.code) {
        case 'view':
          this.formName = 'Form';
          this.formConfig.row = row;
          this.formConfig.code = 'view';
          this.openFull();
          break;
        default:
          break;
      }
    },
  },
};
</script>
