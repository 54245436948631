<!--
 * @Autor: yzr
 * @Date: 2021-08-10 10:27:33
 * @LastEditors: yzr
 * @LastEditTime: 2021-08-31 14:26:35
 * @Description: 经销商费用余额
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import City from '@/found/views/cps/views/execute/display_task/table/components/city.vue';
import Form from './form';

export default {
  name: 'modules',
  extends: TablePage,
  components: {
    Form,
    City,
  },
  data() {
    return {
      requestType: 'GET',
      requestUrl: '/cps/v1/cost/dealer/findByConditions',
      params: {
      },
      formConfig: {
        code: 'view',
      },
      modalConfig: {},
    };
  },
  async created() {
    await this.getConfigList('cps-rights-dealerBalance-table');
  },
  methods: {
    beforeSetSearch(v) {
      const item = v;
      if (item.field === 'area') {
        item.typeName = 'City';
        item.type = 'customSearch';
      }
      return item;
    },
    setColumn(v) {
      const rowData = v;
      return rowData;
    },
    modalClick({ val, row }) {
      this.modalConfig = {
        title: '客户分利费用明细',
      };
      switch (val.code) {
        case 'detail':
          this.formConfig.row = row;
          this.formConfig.code = 'view';
          this.openFull();
          break;
        default:
          break;
      }
    },
    clickVisible({ val, row }) {
      return true;
    },
  },
};
</script>
