<!--
 * @Autor: yzr
 * @Date: 2021-08-23 09:49:34
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-28 16:44:07
 * @Description: 明细表单
-->
<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import DetailTable from '../../../../components/detailTable.vue';

formCreate.component('detailTable', DetailTable);

export default {
  name: 'Form',
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'cps-rights-dealerBalance-form',
    };
  },

  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'detailTable') {
        item.props = {
          ...item.props,
          columns: [
            {
              field: 'sourceType',
              label: '业务类型',
            },
            {
              field: 'sourceCode',
              label: '业务来源单号',
            },
            {
              field: 'amount',
              label: '分利费用',
            },
            // {
            //   field: 'billedFee',
            //   label: '已上账费用',
            // },
            // {
            //   field: 'unBilledFee',
            //   label: '未上账费用',
            // },
            // {
            //   field: 'billState',
            //   label: '上账状态',
            //   formatter: ({ cellValue }) => {
            //     let billState = '';
            //     if (cellValue === 1) billState = '已上账';
            //     else billState = '未上账';
            //     return billState;
            //   },
            // },
            {
              field: 'createTime',
              label: '创建时间',
            },
            {
              field: 'createAccount',
              label: '创建人',
            },
            {
              field: 'remark',
              label: '备注',
            },
          ],
          statisInfo: [
            {
              key: '分利总费用',
              value: this.formConfig.row.totalAmount,
            },
            {
              key: '流转总费用',
              value: this.formConfig.row.terminalCirculationFee,
            },
            {
              key: '已上账总费用',
              value: this.formConfig.row.billedFee,
            },
            {
              key: '未上账总费用',
              value: this.formConfig.row.unBilledFee,
            },
          ],
          records: [],
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    formComplete() {
      this.getDetail();
    },
    // 表单提交
    submit() {

    },
    getDetail() {
      request.get('/cps/v1/cost/dealerDetail/findByCostDealerId', { costDealerId: this.formConfig.row.id }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.setValue({
          ...this.formConfig.row,
          type: '经销商',
        });
        this.getRule('detailTable').props.records = res.result.records;
      });
    },
  },
};
</script>
